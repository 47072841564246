import * as React from 'react';
import { Layout } from '../components/Layout';
import { Title } from '../components/Title';
import { Form } from '../components/Form';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { Contacts } from '../components/Contacts';
import { Seo } from '../components/Seo';

function IndexSite({ location }) {
    return (
        <Layout location={location}>
            <Breadcrumbs
                crumbs={[
                    ['/', 'Главная'],
                    [null, 'Контакты'],
                ]}
            />
            <div className="container">
                <Title className="h1" level="h1">
                    Контакты
                </Title>
            </div>
            <div className="container section-margin">
                <Contacts />
            </div>

            <Form />
        </Layout>
    );
}

export default IndexSite;

export function Head() {
    return (
        <Seo
            title="Контакты - ГЛАВТОРГИ - тендерное бюро Титовой Елены"
            description="Контактная информация компании ГЛАВТОРГИ - тендерное бюро Титовой Елены"
        />
    );
}
