import * as React from 'react';

const Contacts = () => {
    return (
        <div className="contacts">
            <div className="contacts__items">
                <div className="contacts__item">
                    <svg
                        className="contacts__item-icon"
                        width="30"
                        height="30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M26.426 21.914c-.93-.938-3.184-2.306-4.277-2.857-1.424-.717-1.541-.776-2.66.056-.747.555-1.243 1.05-2.117.864-.874-.186-2.772-1.237-4.434-2.894-1.663-1.657-2.774-3.61-2.961-4.481-.187-.871.317-1.361.866-2.11.775-1.054.716-1.23.054-2.654-.516-1.107-1.924-3.34-2.865-4.266-1.007-.994-1.007-.818-1.655-.549a9.384 9.384 0 0 0-1.514.807c-.937.623-1.457 1.14-1.821 1.918-.364.778-.528 2.6 1.351 6.014 1.88 3.414 3.198 5.16 5.927 7.88 2.728 2.721 4.827 4.184 7.894 5.905 3.795 2.125 5.25 1.71 6.03 1.348.78-.364 1.3-.88 1.924-1.817a9.33 9.33 0 0 0 .808-1.512c.27-.646.446-.646-.55-1.652Z"
                            stroke="#333333"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                        />
                    </svg>
                    <div className="contacts__item-title">Телефон для справок:</div>
                    <a href="tel:+79536234923" className="contacts__item-link">
                        8 (953) 623-49-23
                    </a>
                </div>
                <div className="contacts__item">
                    <svg
                        className="contacts__item-icon"
                        width="32"
                        height="32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M16.708 2.292a1 1 0 0 0-1.416 0l-12 12A1 1 0 0 0 3 15v14a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1v-8h4v8a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1V15a1.001 1.001 0 0 0-.292-.708L26 11.586V5a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v2.586l-5.292-5.294ZM5 28V15.414l11-11 11 11V28h-7v-8a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v8H5Z"
                            fill="#333333"
                        />
                    </svg>
                    <div className="contacts__item-title">Адрес:</div>
                    <div className="contacts__item-text">
                        Россия, 119334, Орел,
                        <br />
                        ул.1-я Курская д. 72
                    </div>
                </div>
                <div className="contacts__item">
                    <svg
                        className="contacts__item-icon"
                        width="30"
                        height="30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M25.875 10.055 15.637 5.002a1.44 1.44 0 0 0-1.274 0L4.125 10.055a2.343 2.343 0 0 0-1.313 2.097v10.817c0 1.294 1.063 2.343 2.375 2.343h19.629c1.311 0 2.374-1.049 2.374-2.343V12.152a2.344 2.344 0 0 0-1.315-2.097v0Z"
                            stroke="#333333"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="m3.594 11.25 8.144 6.152m11.543 4.16-7.574-5.89a1.407 1.407 0 0 0-1.727 0l-7.574 5.89h16.875Zm-5.156-4.277 7.968-6.035-7.968 6.035Z"
                            stroke="#333333"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    <div className="contacts__item-title">Контактная почта:</div>
                    <a href="emailto:titova@glav-torgi.ru" className="contacts__item-link">
                        titova@glav-torgi.ru
                    </a>
                </div>
            </div>
        </div>
    );
};

export { Contacts };
